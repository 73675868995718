import * as types from './types';

const initialState = {
    loadingState: false,
    savingState: false,
    users: [],
    officers: [],
    profileImages: [],
    totalUsers: 0,
    selectedUser: null,
    apiErrors: null
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.USERS_LOADING_STATE:
            return {
                ...state,
                loadingState: action.state
            };

        case types.USERS_SAVING_STATE:
            return {
                ...state,
                savingState: action.state
            };

        case types.GET_USERS_SUCCESS:
            return {
                ...state,
                users: action.users,
                totalUsers: action.totalUsers
            };

        case types.CREATE_USER_SUCCESS:
            return {
                ...state,
                users: [...state.users, action.user],
                totalUsers: state.totalUsers + 1,
                apiErrors: null
            };

        case types.ASSIGN_OFFICER_ROLE_SUCCESS:
        case types.REMOVE_OFFICER_ROLE_SUCCESS:
            return {
                ...state,
                users: state.users.map(user => {
                    if (user._id === action.user._id) return action.user;
                    return user;
                }),
                apiErrors: null
            };

        case types.GET_OFFICERS_SUCCESS:
            return {
                ...state,
                officers: action.officers
            };

        case types.ARCHIVE_USER_SUCCESS:
            return {
                ...state,
                users: action.users
            };

        case types.GET_USERS_ERROR:
        case types.ASSIGN_OFFICER_ROLE_ERROR:
        case types.REMOVE_OFFICER_ROLE_ERROR:
        case types.GET_OFFICERS_ERROR:
        case types.INVITE_USER_TO_COMMUNITY_ERROR:
        case types.CREATE_USER_ERROR:
            return {
                ...state,
                apiErrors: action.errors || []
            };

        default:
            return state;
    }
};

export default userReducer;

export const selectUsersLoadingState = state => state.users.loadingState;
export const selectUsersSavingState = state => state.users.savingState;
export const selectUsers = state => state.users.users;
export const selectTotalUsers = state => state.users.totalUsers;
export const selectSelectedUsers = state => state.users.selectedUser;
export const selectAllOfficers = state => state.users.officers;
export const selectUsersApiErrors = state => state.users.apiErrors;
export const selectProfileImages = state => state.users.profileImages;
