import axios from 'axios';
import * as authServices from './services';

export const attemptLogin = async payload => {
    try {
        const result = await axios.post('/v1/auth/login', payload);
        return result.data;
    } catch (error) {
        throw error;
    }
};

export const refreshToken = async () => {
    try {
        const token = authServices.getLocalStore('refreshToken');
        const result = await axios.post('/v1/auth/token/refresh', {
            refreshToken: token
        });
        return result.data;
    } catch (error) {
        throw error;
    }
};

export const requestPasswordReset = async email => {
    console.log("1", process.env.NODE_ENV)
    console.log("2", window.__env__.SET_PASSWORD_ROUTE)
    console.log("3", process.env.REACT_APP_CMS_URL, process.env.REACT_APP_SET_PASSWORD_ROUTE)
    try {
        const isProd = 'NODE_ENV' in process.env && process.env.NODE_ENV === 'production';
        const url = isProd
            ? `${window.__env__.SET_PASSWORD_ROUTE}`
            : `${process.env.REACT_APP_SET_PASSWORD_ROUTE}`;

        // console.log("ENVIRONMENT CHECK 1", process.env.CMS_URL, process.env.SET_PASSWORD_ROUTE)
        // process.env.REACT_APP_CMS_URL, process.env.REACT_APP_SET_PASSWORD_ROUTE
        const result = await axios.post('/v1/auth/password/request', { 
            email,
            url
        });
        return result.data;
    } catch (error) {
        throw error;
    }
};

export const resetPassword = async (password, passwordConfirm, code) => {
    try {
        const result = await axios.post('/v1/auth/password/reset', {
            password,
            passwordConfirm,
            code
        });
        return result.data;
    } catch (error) {
        throw error;
    }
};
