export const GET_USERS = 'users/GET_USERS';
export const GET_USERS_SUCCESS = 'users/GET_USERS_SUCCESS';
export const GET_USERS_ERROR = 'users/GET_USERS_ERROR';

export const GET_OFFICERS = 'users/GET_OFFICERS';
export const GET_OFFICERS_SUCCESS = 'users/GET_OFFICERS_SUCCESS';
export const GET_OFFICERS_ERROR = 'userse/GET_OFFICERS_ERROR';

export const GET_USER = 'users/GET_USER';
export const GET_USER_SUCCESS = 'users/GET_USER_SUCCESS';
export const GET_USER_ERROR = 'users/GET_USER_ERROR';

export const CREATE_USER = 'users/CREATE_USER';
export const CREATE_USER_SUCCESS = 'users/CREATE_USER_SUCCESS';
export const CREATE_USER_ERROR = 'users/CREATE_USER_ERROR';

export const INVITE_USER_TO_COMMUNITY = 'users/INVITE_USER_TO_COMMUNITY';
export const INVITE_USER_TO_COMMUNITY_SUCCESS =
    'users/INVITE_USER_TO_COMMUNITY_SUCCESS';
export const INVITE_USER_TO_COMMUNITY_ERROR = 'users/INVITE_USER_TO_COMMUNITY_ERROR';

export const STORE_PROFILE_IMAGE = 'users/STORE_PROFILE_IMAGE';

export const UPDATE_USER = 'users/UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'users/UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'users/UPDATE_USER_ERROR';

export const ASSIGN_OFFICER_ROLE = 'users/ASSIGN_OFFICER_ROLE';
export const ASSIGN_OFFICER_ROLE_SUCCESS = 'users/ASSIGN_OFFICER_ROLE_SUCCESS';
export const ASSIGN_OFFICER_ROLE_ERROR = 'users/ASSIGN_OFFICER_ROLE_ERROR';

export const REMOVE_OFFICER_ROLE = 'users/REMOVE_OFFICER_ROLE';
export const REMOVE_OFFICER_ROLE_SUCCESS = 'users/REMOVE_OFFICER_ROLE_SUCCESS';
export const REMOVE_OFFICER_ROLE_ERROR = 'users/REMOVE_OFFICER_ROLE_ERROR';

export const ARCHIVE_USER = 'users/ARCHIVE_USER';
export const ARCHIVE_USER_SUCCESS = 'users/ARCHIVE_USER_SUCCESS';
export const ARCHIVE_USER_ERROR = 'users/ARCHIVE_USER_ERROR';

export const USERS_LOADING_STATE = 'users/USERS_LOADING_STATE';
export const USERS_SAVING_STATE = 'users/USERS_SAVING_STATE';
